import * as React from "react"
import { useState, useEffect } from "react"
import { graphql } from "gatsby"
import { Row, Col, Form, Alert, Button } from "react-bootstrap"
import Img from "gatsby-image"
import addToMailchimp from "gatsby-plugin-mailchimp"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ReviewsCarousel from "../components/reviews-carousel"
import env from "../config/env";

import * as states from "../config/usa-states-and-cities"
import * as globalStyles from "../styles/global.module.css"
import * as homepageStyle from "./index.module.css"
import * as formStyle from "./form.module.css"

const IndexPage = ({ data }) => {
    const resourceImages = {}
    data.reviewsImages.edges.map(item => {
        resourceImages[item.node.name] = item.node
    })

    const galleryImages = {}
    data.gallery.edges.map(photo => {
        galleryImages[photo.node.name] = photo.node
    })

    const [usState, setUSState] = useState("")
    const [cities, setCities] = useState([])
    const [showEmailErrorMessage, setShowEmailErrorMessage] = useState({
        show: false,
        message: "",
    })
    const [showStateErrorMessage, setShowStateErrorMessage] = useState({
        show: false,
        message: "",
    })
    const [showCityErrorMessage, setShowCityErrorMessage] = useState({
        show: false,
        message: "",
    })
    const [showNameErrorMessage, setShowNameErrorMessage] = useState({
        show: false,
        message: "",
    })
    const [
        showOccupationErrorMessage,
        setShowOccupationErrorMessage,
    ] = useState({ show: false, message: "" })
    const [
        showBabyBirthdayErrorMessage,
        setShowBabyBirtdayErrorMessage,
    ] = useState({ show: false, message: "" })
    const [showLeaderErrorMessage, setShowLeaderErrorMessage] = useState({
        show: false,
        message: "",
    })
    const [showGroupTypeErrorMessage, setShowGroupTypeErrorMessage] = useState({
        show: false,
        message: "",
    })
    const [formResult, setFormResult] = useState({})
    const [showAlert, setShowAlert] = useState({ show: false, msg: "" })
    const [showError, setShowErrorAlert] = useState({
        show: false,
        onField: "",
    })
    const [showPartnerAlert, setShowPartnerAlert] = useState({
        type: "danger",
        show: false,
        msg: "",
    })
    const [showPartnerError, setShowPartnerErrorAlert] = useState({
        show: false,
        onField: "",
    })
    const applyingUrl =
        "https://momsonmaternity.us17.list-manage.com/subscribe/post?u=abb88870b50fd9c89b14ecf52&amp;id=3754a8f976"
    const applyingPartnerUrl =
        "https://momsonmaternity.us17.list-manage.com/subscribe/post?u=abb88870b50fd9c89b14ecf52&amp;id=c53ccbbc0c"
    const [
        showEmailPartnerErrorMessage,
        setShowEmailPartnerErrorMessage,
    ] = useState({ show: false, message: "" })
    const [
        showPartnerDescriptionErrorMessage,
        setShowPartnerDescriptionErrorMessage,
    ] = useState({ show: false, message: "" })
    const [
        applyToBeConnectedFormState,
        setApplyToBeConnectedFormValues,
    ] = useState({
        name: "",
        email: "",
        babyBirthday: "",
        occupation: "",
        leader: "Yes",
        groupType: "All-Moms Group",
        state: "",
        city: "",
    })
    const [
        applyToBeOurPartnerFormState,
        setApplyToBeOurPartnerFormValues,
    ] = useState({
        partnerEmail: "",
        partnerDescription: "",
    })

    const validateForm = () => {
        let isValid = true

        if (!applyToBeConnectedFormState.name) {
            isValid = false
            setShowNameErrorMessage({
                show: true,
                message: "Please enter your name!",
            })
        }

        if (!applyToBeConnectedFormState.email) {
            isValid = false
            setShowEmailErrorMessage({
                show: true,
                message: "Please enter your email!",
            })
        }

        if (!applyToBeConnectedFormState.babyBirthday) {
            isValid = false

            setShowBabyBirtdayErrorMessage({
                show: true,
                message: "Please add your baby birthday or due date!",
            })
        }

        if (!applyToBeConnectedFormState.state) {
            isValid = false
            setShowStateErrorMessage({
                show: true,
                message: "Please choose your state!",
            })
        }

        if (!applyToBeConnectedFormState.city) {
            isValid = false
            setShowCityErrorMessage({
                show: true,
                message: "Please choose your city!",
            })
        }

        if (!applyToBeConnectedFormState.occupation) {
            isValid = false
            setShowOccupationErrorMessage({
                show: true,
                message: "Please enter your occupation!",
            })
        }

        return isValid
    }

    const validatePartnersForm = () => {
        let isValid = true

        if (!applyToBeOurPartnerFormState.partnerEmail) {
            isValid = false
            setShowEmailPartnerErrorMessage({
                show: true,
                message: "Please enter your email!",
            })
        }

        if (!applyToBeOurPartnerFormState.partnerDescription) {
            isValid = false

            setShowPartnerDescriptionErrorMessage({
                show: true,
                message: "Please add your description!",
            })
        }

        return isValid
    }

    const populateCities = () => {
        if (!usState) {
            setCities([])
            return
        }
        const cities = []
        for (const city of states[usState]) {
            cities.push(city)
        }
        cities.sort()
        setCities(cities)
    }

    useEffect(() => {
        populateCities()
    }, [usState])

    const selectUSState = event => {
        setApplyToBeConnectedFormValues({
            ...applyToBeConnectedFormState,
            city: "",
        })
        setUSState(event.target.value)
        setApplyToBeConnectedFormValues({
            ...applyToBeConnectedFormState,
            state: event.target.value,
        })
        setUSState(event.target.value)
    }

    const selectCity = event => {
        setApplyToBeConnectedFormValues({
            ...applyToBeConnectedFormState,
            city: event.target.value,
        })
    }

    const handleApplyToBeConnectedFormChange = e => {
        let value = e.target.value
        setApplyToBeConnectedFormValues({
            ...applyToBeConnectedFormState,
            [e.target.name]: value,
        })
    }

    const handleApplyToBeOurPartnerFormChange = e => {
        let value = e.target.value
        setApplyToBeOurPartnerFormValues({
            ...applyToBeOurPartnerFormState,
            [e.target.name]: value,
        })
    }

    const saveToMailchimp = async () => {
        try {
            const result = await addToMailchimp(
                applyToBeConnectedFormState.email,
                {
                    NAME: applyToBeConnectedFormState.name,
                    EMAIL: applyToBeConnectedFormState.email,
                    BIRTHDAY: applyToBeConnectedFormState.babyBirthday,
                    OCCUPATION: applyToBeConnectedFormState.occupation,
                    LEADER: applyToBeConnectedFormState.leader,
                    GROUPTYPE: applyToBeConnectedFormState.groupType,
                    STATE: applyToBeConnectedFormState.state,
                    CITY: applyToBeConnectedFormState.city,
                },
                applyingUrl
            )

            const alreadyAMemberError =
                applyToBeConnectedFormState.email +
                ` is already subscribed to list`
            setFormResult(result)
            if (
                result.result === "error" &&
                result.msg.includes(alreadyAMemberError)
            ) {
                setShowAlert({
                    show: true,
                    msg: "You already are a member!",
                })
            } else {
                setShowAlert({
                    show: true,
                    msg: "Welcome to the Club!",
                })
            }
        } catch (err) {
            console.error(err)
        }
        return true
    }

    const savePartnersToMailchimp = async () => {
        console.log(
            `starting savePartnersToMailchimp: ${applyToBeOurPartnerFormState}`
        )
        try {
            const result = await addToMailchimp(
                applyToBeOurPartnerFormState.partnerEmail,
                {
                    EMAIL: applyToBeOurPartnerFormState.partnerEmail,
                    ABOUT: applyToBeOurPartnerFormState.partnerDescription,
                },
                applyingPartnerUrl
            )
            /*
            console.log(`got result: `)
            console.log(JSON.stringify(result, null, 2))
            */

            const alreadyAMemberError =
                applyToBeOurPartnerFormState.partnerEmail +
                ` is already subscribed to list`
            setFormResult(result)
            if (
                result.result === "error" &&
                result.msg.includes(alreadyAMemberError)
            ) {
                setShowPartnerAlert({
                    type: "danger",
                    show: true,
                    msg: "You've already submitted this email",
                })
            } else {
                setShowPartnerAlert({
                    type: "success",
                    show: true,
                    msg: "Thank you for your inquiry. We will be in touch shortly"
                })
            }
        } catch (err) {
            console.error(`got error: ${err}`)
        }
        return true
    }

    const validateAndSaveApplyToBeConnectedFormData = async () => {
        if (validateForm()) {
            await saveToMailchimp()
        }
    }

    const validateAndSaveApplyToBeOurPartnerFormData = async () => {
        console.log(applyToBeOurPartnerFormState)
        if (validatePartnersForm()) {
            await savePartnersToMailchimp()
        }
    }

    return (
        <Layout
            disableLayoutPadding={true}
            structuredData={{
                "@context": "https://schema.org",
                "@type": "Product",
                "name": "Tummy Time Club",
                "legalName": "Tummy Time Club, Inc.",
                "url": "https://www.tummytimeclub.com/",
                "foundingDate": "2021",
                "founders": [
                    {
                        "@type": "Person",
                        "name": "Aimee M Cruz"
                    }
                ],
                "sameAs": [
                    "https://www.facebook.com/MomsOnMaternity/",
                    "https://www.youtube.com/channel/UCCAGR4adI21ao1Jp7CTY32g",
                    "https://www.instagram.com/momsonmaternity/"
                ],
                "address": {
                    "@type": "PostalAddress",
                    "streetAddress": "4403 Muir Ave",
                    "addressLocality": "San Diego",
                    "addressRegion": "CA",
                    "postalCode": "92107",
                    "addressCountry": "USA"
                },
                "logo": {
                    "@type": "ImageObject",
                    "url": env.domain + data.logo.publicURL
                },

                "image": env.domain + data.logo.publicURL,
                "offers" : {
                    "@type" : "Offer",
                    "price" : "40",
                    "priceCurrency": "USD",
                    "url" : "https://www.tummytimeclub.com/"
                },
                "description": "Tummy Time Club is your perfect getaway to join other women and men in learning about the parenthood lifestyle. Our goal is to bring together new Moms and Dads into this beautiful community where we speak freely and openly about our experiences and questions."
            }}
        >
            <Seo title="Home" />
            <Row>
                <Col>
                    <Row>
                        <Col>
                            <h1
                                className={[
                                    globalStyles.entryTitle,
                                    homepageStyle.title,
                                ].join(" ")}
                                // className={[styles.footerContainer, "container-fluid"].join(" ")}
                            >
                                Are you a new or expecting Parent?
                                <br />
                                Join our Club where we talk babies, Mom life,
                                Dad life, business and careers.
                            </h1>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <h3
                                className={[
                                    globalStyles.subTitle,
                                    homepageStyle.homepageSubtitle,
                                ].join(" ")}
                            >
                                We match you up into a group of 6-8 other new
                                Moms or Dads just like you!
                            </h3>
                        </Col>
                    </Row>

                    <Row
                        style={{ marginTop: "3rem" }}
                        className={homepageStyle.galleryContent}
                    >
                        <Col style={{ marginTop: "2rem" }}>
                            <div>
                                <Img
                                    fluid={
                                        data.groupPhoto.childImageSharp.fluid
                                    }
                                ></Img>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <h1
                                className={[
                                    globalStyles.entryTitle,
                                    homepageStyle.title,
                                ].join(" ")}
                            >
                                ABOUT
                            </h1>
                        </Col>
                    </Row>

                    <Row className={homepageStyle.about}>
                        <Col>
                            <p>
                                <span>Tummy Time Club</span> Tummy Time Club is your place to connect with others who are in
                                the same life stage as you! Enjoy your hand-picked community at a special time of your life.
                                It's so fun to make friends with other new parents!
                            </p>
                        </Col>
                    </Row>

                    <Row id="howItWorksSection">
                        <Col>
                            <h1
                                className={[
                                    globalStyles.entryTitle,
                                    homepageStyle.title,
                                ].join(" ")}
                            >
                                How does it work?
                            </h1>
                        </Col>
                    </Row>

                    <Row className={[homepageStyle.processContent].join(" ")}>
                        <Col md={3} sm={12} xs={12}>
                            <Img
                                className={homepageStyle.icon}
                                fluid={data.fillFormIcon.childImageSharp.fluid}
                            />
                            <p className={homepageStyle.aboutContent}>
                                Once you fill out the club request form, congratulations! You are one step closer to
                                joining a fantastic group where you chat with other new parents.
                            </p>
                        </Col>
                        <Col md={3} sm={12} xs={12}>
                            <Img
                                className={homepageStyle.icon}
                                fluid={data.matchingIcon.childImageSharp.fluid}
                            />
                            <p className={homepageStyle.aboutContent}>
                                We match you into a group based on your baby or toddlers birthday or your due date. We
                                also do our best to do this by geographical location and career area as well. If you
                                like the group you get matched into, there is a one time contribution of $40 for your membership.
                            </p>
                        </Col>
                        <Col md={3} sm={12} xs={12}>
                            <Img
                                className={homepageStyle.icon}
                                fluid={data.zoomIcon.childImageSharp.fluid}
                            />
                            <p className={homepageStyle.aboutContent}>
                                Your group will be created on WhatsApp! This is where you all can communicate on every and anything baby!
                                Each group may decide to meet via video (Zoom or Google Meet) monthly or in person for a playdate.
                                Some groups even plan a trip to get to know each other better.
                            </p>
                        </Col>
                        <Col md={3} sm={12} xs={12}>
                            <Img
                                className={homepageStyle.icon}
                                fluid={data.whatsappIcon.childImageSharp.fluid}
                            />
                            <p className={homepageStyle.aboutContent}>
                                Outside of the WhatsApp group chat, there are periodic webinars where you will have fun
                                interacting with Moms on Maternity leadership as well as experts in Child Development,
                                Family Wellness, Personal Growth and Self Care! We also bring on business leaders too for business tips.
                            </p>
                        </Col>
                    </Row>

                    <Row
                        className={[
                            homepageStyle.carouselContent,
                            "carouselContent",
                        ].join(" ")}
                    >
                        <Col>
                            <Carousel
                                additionalTransfrom={0}
                                arrows
                                autoPlaySpeed={3000}
                                autoPlay={true}
                                centerMode={false}
                                className=""
                                containerClass="container-with-dots"
                                dotListClass=""
                                draggable
                                focusOnSelect={false}
                                infinite
                                itemClass=""
                                keyBoardControl
                                minimumTouchDrag={80}
                                renderButtonGroupOutside={false}
                                renderDotsOutside={false}
                                responsive={{
                                    desktop: {
                                        breakpoint: {
                                            max: 3000,
                                            min: 1024,
                                        },
                                        items: 3,
                                        partialVisibilityGutter: 40,
                                    },
                                    mobile: {
                                        breakpoint: {
                                            max: 464,
                                            min: 0,
                                        },
                                        items: 1,
                                        partialVisibilityGutter: 30,
                                    },
                                    tablet: {
                                        breakpoint: {
                                            max: 1024,
                                            min: 464,
                                        },
                                        items: 2,
                                        partialVisibilityGutter: 30,
                                    },
                                }}
                                showDots={false}
                                sliderClass=""
                                slidesToSlide={1}
                                swipeable
                            >
                                {data.reviews.siteMetadata.reviews.map(
                                    review => (
                                        <div key={review.name}>
                                            <ReviewsCarousel
                                                photo={
                                                    resourceImages[
                                                        review.photo
                                                    ]["childImageSharp"][
                                                        "fluid"
                                                    ]
                                                }
                                            ></ReviewsCarousel>
                                        </div>
                                    )
                                )}
                            </Carousel>
                        </Col>
                    </Row>

                    <Row
                        style={{ marginTop: "3rem" }}
                        className={homepageStyle.galleryContent}
                    >
                        <Col
                            md={3}
                            sm={4}
                            xs={12}
                            style={{ marginTop: "2rem" }}
                        >
                            <div>
                                <Img
                                    fluid={
                                        galleryImages["gallery1"][
                                            "childImageSharp"
                                        ]["fluid"]
                                    }
                                ></Img>
                            </div>
                        </Col>

                        <Col
                            md={3}
                            sm={4}
                            xs={12}
                            style={{ marginTop: "2rem" }}
                        >
                            <div>
                                <Img
                                    fluid={
                                        galleryImages["gallery2"][
                                            "childImageSharp"
                                        ]["fluid"]
                                    }
                                ></Img>
                            </div>
                        </Col>

                        <Col
                            md={3}
                            sm={4}
                            xs={12}
                            style={{ marginTop: "2rem" }}
                        >
                            <div>
                                <Img
                                    fluid={
                                        galleryImages["gallery3"][
                                            "childImageSharp"
                                        ]["fluid"]
                                    }
                                ></Img>
                            </div>
                        </Col>

                        <Col
                            md={3}
                            sm={4}
                            xs={12}
                            style={{ marginTop: "2rem" }}
                        >
                            <div>
                                <Img
                                    fluid={
                                        galleryImages["gallery4"][
                                            "childImageSharp"
                                        ]["fluid"]
                                    }
                                ></Img>
                            </div>
                        </Col>
                    </Row>

                    <Row id="formSection" className={homepageStyle.formSection}>
                        <Col>
                            <h1
                                className={[
                                    globalStyles.entryTitle,
                                    homepageStyle.title,
                                ].join(" ")}
                            >
                                Get Connected to Your Club Now!
                            </h1>
                        </Col>
                    </Row>

                    <Row className={homepageStyle.formSection}>
                        <Col md={6} sm={12} xs={12}>
                            <Form>
                                <Row
                                    className={[
                                        globalStyles.rowStyle,
                                        "justify-content-md-left",
                                    ].join(" ")}
                                >
                                    <Form.Group
                                        as={Col}
                                        controlId="applyToBeConnectedName"
                                        name="yourName"
                                    >
                                        <Form.Label
                                            className={[
                                                formStyle.formLabel,
                                                formStyle.required,
                                            ].join(" ")}
                                        >
                                            Your name:
                                        </Form.Label>
                                        <Form.Control
                                            size="sm"
                                            type="text"
                                            name="name"
                                            value={
                                                applyToBeConnectedFormState.name
                                            }
                                            onChange={e => {
                                                handleApplyToBeConnectedFormChange(
                                                    e
                                                )
                                                setShowNameErrorMessage({
                                                    show: false,
                                                })
                                            }}
                                        />
                                    </Form.Group>
                                </Row>

                                <Row>
                                    <Col xs={12} md={12} sm={12}>
                                        {showNameErrorMessage.show && (
                                            <Alert
                                                variant="danger"
                                                onClose={() =>
                                                    setShowNameErrorMessage({
                                                        show: false,
                                                    })
                                                }
                                                dismissible
                                            >
                                                {showNameErrorMessage.message}
                                            </Alert>
                                        )}
                                    </Col>
                                </Row>

                                <Row
                                    className={[
                                        globalStyles.rowStyle,
                                        "justify-content-md-left",
                                    ].join(" ")}
                                >
                                    <Col xs={12} sm={12} md={12}
                                         style={{"paddingRight": "0px", "paddingLeft": "0px"}}>
                                        <Form.Group
                                            as={Col}
                                            controlId="email"
                                            name="email"
                                        >
                                            <Form.Label
                                                className={[
                                                    formStyle.formLabel,
                                                    formStyle.required,
                                                ]}
                                            >
                                                Email address
                                            </Form.Label>
                                            <Form.Control
                                                size="sm"
                                                value={
                                                    applyToBeConnectedFormState.email
                                                }
                                                type="email"
                                                name="email"
                                                onChange={e => {
                                                    handleApplyToBeConnectedFormChange(
                                                        e
                                                    )
                                                    setShowEmailErrorMessage({
                                                        show: false,
                                                    })
                                                }}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={12} md={12} sm={12}>
                                        {showEmailErrorMessage.show && (
                                            <Alert
                                                variant="danger"
                                                onClose={() =>
                                                    setShowEmailErrorMessage({
                                                        show: false,
                                                    })
                                                }
                                                dismissible
                                            >
                                                {showEmailErrorMessage.message}
                                            </Alert>
                                        )}
                                    </Col>
                                </Row>

                                <Row
                                    className={[
                                        globalStyles.rowStyle,
                                        "justify-content-md-left",
                                    ].join(" ")}
                                >
                                    <Form.Group
                                        as={Col}
                                        controlId="babyBirthday"
                                        name="babyBirthday"
                                    >
                                        <Form.Label
                                            className={[
                                                formStyle.formLabel,
                                                formStyle.required,
                                            ].join(" ")}
                                        >
                                            Babies’ birthday or due date:
                                        </Form.Label>
                                        <Form.Control
                                            size="sm"
                                            type="text"
                                            name="babyBirthday"
                                            value={
                                                applyToBeConnectedFormState.babyBirthday
                                            }
                                            onChange={e => {
                                                handleApplyToBeConnectedFormChange(
                                                    e
                                                )
                                                setShowNameErrorMessage({
                                                    show: false,
                                                })
                                            }}
                                        />
                                    </Form.Group>
                                </Row>

                                <Row>
                                    <Col xs={12} md={12} sm={12}>
                                        {showBabyBirthdayErrorMessage.show && (
                                            <Alert
                                                variant="danger"
                                                onClose={() =>
                                                    setShowBabyBirtdayErrorMessage(
                                                        { show: false }
                                                    )
                                                }
                                                dismissible
                                            >
                                                {
                                                    showBabyBirthdayErrorMessage.message
                                                }
                                            </Alert>
                                        )}
                                    </Col>
                                </Row>

                                <Row
                                    className={[
                                        globalStyles.rowStyle,
                                        "justify-content-md-left",
                                    ].join(" ")}
                                >
                                    <Col md={12} sm={12} xs={12}
                                         style={{"paddingRight": "0px", "paddingLeft": "0px"}}>
                                        <Form.Group
                                            as={Col}
                                            controlId="applyToBeConnectedState"
                                        >
                                            <Form.Label
                                                className={[
                                                    formStyle.formLabel,
                                                    formStyle.required,
                                                ]}
                                            >
                                                State
                                            </Form.Label>
                                            <Form.Control
                                                as="select"
                                                onChange={e => {
                                                    selectUSState(e)
                                                    setShowStateErrorMessage({
                                                        show: false,
                                                    })
                                                }}
                                                name="zoomInviteState"
                                            >
                                                <option value="">
                                                    Select Your State
                                                </option>
                                                {Object.keys(states).map(
                                                    state => (
                                                        <option
                                                            style={{
                                                                background:
                                                                    "#5cb85c",
                                                                color: "#fff",
                                                            }}
                                                            key={state}
                                                            value={state}
                                                        >
                                                            {state}
                                                        </option>
                                                    )
                                                )}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>

                                    <Row>
                                        <Col xs={12} md={12} sm={12}>
                                            {showStateErrorMessage.show && (
                                                <Alert
                                                    variant="danger"
                                                    onClose={() =>
                                                        setShowStateErrorMessage(
                                                            { show: false }
                                                        )
                                                    }
                                                    dismissible
                                                >
                                                    {
                                                        showStateErrorMessage.message
                                                    }
                                                </Alert>
                                            )}
                                        </Col>
                                    </Row>

                                    <Col
                                        className={[
                                            globalStyles.rowStyle,
                                            "justify-content-md-left",
                                        ].join(" ")}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                    >
                                        <Form.Group
                                            as={Col}
                                            controlId="applyToBeConnectedCity"
                                            style={{"paddingRight": "0px", "paddingLeft": "0px"}}
                                        >
                                            <Form.Label
                                                className={[
                                                    formStyle.formLabel,
                                                    formStyle.required,
                                                ]}
                                            >
                                                City
                                            </Form.Label>
                                            <Form.Control
                                                as="select"
                                                onChange={e => {
                                                    selectCity(e)
                                                    setShowCityErrorMessage({
                                                        show: false,
                                                    })
                                                }}
                                                name="city"
                                            >
                                                <option value="">
                                                    Select Your City
                                                </option>
                                                {cities.map(city => (
                                                    <option
                                                        key={city}
                                                        value={city}
                                                    >
                                                        {city}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={12} md={12} sm={12}>
                                        {showCityErrorMessage.show && (
                                            <Alert
                                                variant="danger"
                                                onClose={() =>
                                                    setShowCityErrorMessage({
                                                        show: false,
                                                    })
                                                }
                                                dismissible
                                            >
                                                {showCityErrorMessage.message}
                                            </Alert>
                                        )}
                                    </Col>
                                </Row>

                                <Row
                                    className={[
                                        globalStyles.rowStyle,
                                        "justify-content-md-left",
                                    ].join(" ")}
                                >
                                    <Form.Group
                                        as={Col}
                                        controlId="applyToBeConnectedBirthday"
                                        name="occupation"
                                    >
                                        <Form.Label
                                            className={[
                                                formStyle.formLabel,
                                                formStyle.required,
                                            ].join(" ")}
                                        >
                                            Business occupation/Career area:
                                        </Form.Label>
                                        <Form.Control
                                            size="sm"
                                            type="text"
                                            name="occupation"
                                            value={
                                                applyToBeConnectedFormState.occupation
                                            }
                                            onChange={e => {
                                                handleApplyToBeConnectedFormChange(
                                                    e
                                                )
                                                setShowOccupationErrorMessage({
                                                    show: false,
                                                })
                                            }}
                                        />
                                    </Form.Group>
                                </Row>

                                <Row>
                                    <Col xs={12} md={12} sm={12}>
                                        {showOccupationErrorMessage.show && (
                                            <Alert
                                                variant="danger"
                                                onClose={() =>
                                                    setShowOccupationErrorMessage(
                                                        { show: false }
                                                    )
                                                }
                                                dismissible
                                            >
                                                {
                                                    showOccupationErrorMessage.message
                                                }
                                            </Alert>
                                        )}
                                    </Col>
                                </Row>

                                <Row
                                    className={[
                                        globalStyles.rowStyle,
                                        "justify-content-md-left",
                                    ].join(" ")}
                                >
                                    <Col>
                                        <Form.Group
                                            as={Col}
                                            controlId="leader"
                                            name="leader"
                                            style={{"paddingRight": "0px", "paddingLeft": "0px"}}
                                        >
                                            <Form.Label
                                                className={[
                                                    formStyle.formLabel,
                                                ]}
                                            >
                                                Are you interested in being a
                                                group leader?
                                            </Form.Label>
                                            <Form.Control
                                                as="select"
                                                onChange={e => {
                                                    handleApplyToBeConnectedFormChange(
                                                        e
                                                    )
                                                    setShowLeaderErrorMessage({
                                                        show: false,
                                                    })
                                                }}
                                                value={
                                                    applyToBeConnectedFormState.leader
                                                }
                                                name="leader"
                                            >
                                                {/*<option className="defaultValue" value=""></option>*/}
                                                <option>Yes</option>
                                                <option>No</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={12} md={12} sm={12}>
                                        {showLeaderErrorMessage.show && (
                                            <Alert
                                                variant="danger"
                                                onClose={() =>
                                                    setShowLeaderErrorMessage({
                                                        show: false,
                                                    })
                                                }
                                                dismissible
                                            >
                                                {showLeaderErrorMessage.message}
                                            </Alert>
                                        )}
                                    </Col>
                                </Row>

                                <Row
                                    className={[
                                        globalStyles.rowStyle,
                                        "justify-content-md-left",
                                    ].join(" ")}
                                >
                                    <Col>
                                        <Form.Group
                                            as={Col}
                                            controlId="groupType"
                                            name="groupType"
                                            style={{"paddingRight": "0px", "paddingLeft": "0px"}}
                                        >
                                            <Form.Label
                                                className={[
                                                    formStyle.formLabel,
                                                ]}
                                            >
                                                Select your group type:
                                            </Form.Label>
                                            <Form.Control
                                                as="select"
                                                onChange={e => {
                                                    handleApplyToBeConnectedFormChange(
                                                        e
                                                    )
                                                    setShowGroupTypeErrorMessage(
                                                        { show: false }
                                                    )
                                                }}
                                                value={
                                                    applyToBeConnectedFormState.groupType
                                                }
                                                name="groupType"
                                            >
                                                {/*<option className="defaultValue" value=""></option>*/}
                                                <option>All-Moms Group</option>
                                                <option>All-Dads Group</option>
                                                <option>Co-ed</option>
                                                <option>No-preference</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={12} md={12} sm={12}>
                                        {showGroupTypeErrorMessage.show && (
                                            <Alert
                                                variant="danger"
                                                onClose={() =>
                                                    setShowGroupTypeErrorMessage(
                                                        { show: false }
                                                    )
                                                }
                                                dismissible
                                            >
                                                {
                                                    showGroupTypeErrorMessage.message
                                                }
                                            </Alert>
                                        )}
                                    </Col>
                                </Row>

                                <Row>
                                    <Col
                                        xs={12}
                                        sm={12}
                                        md={{ span: 3, offset: 0 }}
                                    >
                                        <Button
                                            type="button"
                                            className={[
                                                homepageStyle.submitButton,
                                                homepageStyle.homepageButton,
                                                globalStyles.rowStyle,
                                                "justify-content-md-left",
                                            ].join(" ")}
                                            onClick={
                                                validateAndSaveApplyToBeConnectedFormData
                                            }
                                        >
                                            Submit Request
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>

                            <Row>
                                <Col
                                    xs={12}
                                    sm={8}
                                    className={homepageStyle.alertMessage}
                                >
                                    {showAlert.show &&
                                        formResult.result === "error" && (
                                            <Alert
                                                variant="danger"
                                                onClose={() =>
                                                    setShowAlert({
                                                        show: false,
                                                    })
                                                }
                                                dismissible
                                            >
                                                {showAlert.msg}
                                            </Alert>
                                        )}
                                    {showAlert.show &&
                                        formResult.result === "success" && (
                                            <Alert
                                                variant="success"
                                                className={
                                                    globalStyles.succesAlert
                                                }
                                                onClose={() =>
                                                    setShowAlert({
                                                        show: false,
                                                    })
                                                }
                                                dismissible
                                            >
                                                {showAlert.msg}
                                            </Alert>
                                        )}
                                    {showError.show && (
                                        <Alert
                                            variant="danger"
                                            onClose={() =>
                                                setShowErrorAlert({
                                                    show: false,
                                                })
                                            }
                                            dismissible
                                        >
                                            {showError.onField} field should not
                                            be empty
                                        </Alert>
                                    )}
                                </Col>
                            </Row>
                        </Col>

                        <Col md={6} sm={12} xs={12}>
                            <Row className={homepageStyle.bannerContent}>
                                <Col className={homepageStyle.bannerColumn}>
                                    <Row className={homepageStyle.price}>
                                        <Col>
                                            <p>We Believe </p>
                                        </Col>
                                    </Row>

                                    <Row
                                        className={homepageStyle.memberBenefits}
                                    >
                                        <Col>
                                            <h3
                                                className={
                                                    homepageStyle.description
                                                }
                                            >
                                                We can 100% Be Great Moms and Great Professionals!

                                            </h3>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <h1
                              className={[
                                  globalStyles.entryTitle,
                                  homepageStyle.title,
                              ].join(" ")}
                            >
                                Why Community is Important by Moms on Maternity
                            </h1>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={5} sm={8} xs={12} className={"offset-md-1"} className={homepageStyle.aboutCommunitySection}>
                            <div className="embed-responsive embed-responsive-16by9">
                                <iframe className="embed-responsive-item"
                                        src="https://www.youtube.com/embed/tbo9OW-sYn4"
                                        allowFullScreen>
                                </iframe>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <h1
                                className={[
                                    globalStyles.entryTitle,
                                    homepageStyle.title,
                                ].join(" ")}
                            >
                                CONTACT
                            </h1>
                        </Col>
                    </Row>

                    <Row className={homepageStyle.contactSection}>
                        <Col md={5} sm={12} xs={12} className={"offset-md-1"}>
                            <h3 className={homepageStyle.contactStyle}>
                                Send Mail, Email or Direct Message us:
                            </h3>
                            <ul className={homepageStyle.list}>
                                <li>
                                    @MomsOnMaternity on Instagram &amp; Facebook
                                </li>
                                <li>AimeeCruz@MomsOnMaternity.com</li>
                            </ul>
                            <p>
                                Moms on Maternity
                                <br />
                                4967 Newport Ave Ste 12-236
                                <br />
                                San Diego, CA 92107
                                <br />
                            </p>
                        </Col>

                        <Col md={4} sm={12} xs={12} className={"offset-md-1"}>
                            <h3
                                className={[
                                    globalStyles.entryTitle,
                                    homepageStyle.title,
                                ].join(" ")}
                            >
                                Partner with us
                            </h3>
                            <p>
                                Become a Moms on Maternity and Tummy Time Club
                                Partner. Do you have swag or samples you would
                                love to have new Parents try? Would you love to
                                get your brand more well known? We are here for
                                you!
                            </p>

                            <Form>
                                <Row
                                    className={[
                                        globalStyles.rowStyle,
                                        "justify-content-md-left",
                                    ].join(" ")}
                                >
                                    <Form.Group
                                        as={Col}
                                        xs={12}
                                        md={12}
                                        sm={12}
                                        controlId="partnerEmail"
                                        name="partnerEmail"
                                    >
                                        <Form.Label
                                            className={[
                                                formStyle.formLabel,
                                                formStyle.required,
                                            ].join(" ")}
                                        >
                                            Your Email:
                                        </Form.Label>
                                        <Form.Control
                                            size="sm"
                                            type="email"
                                            name="partnerEmail"
                                            value={
                                                applyToBeOurPartnerFormState.partnerEmail
                                            }
                                            onChange={e => {
                                                handleApplyToBeOurPartnerFormChange(
                                                    e
                                                )
                                                setShowEmailPartnerErrorMessage(
                                                    { show: false }
                                                )
                                            }}
                                        />
                                    </Form.Group>
                                </Row>

                                <Row>
                                    <Col xs={12} md={12} sm={12}>
                                        {showEmailPartnerErrorMessage.show && (
                                            <Alert
                                                variant="danger"
                                                onClose={() =>
                                                    setShowEmailPartnerErrorMessage(
                                                        { show: false }
                                                    )
                                                }
                                                dismissible
                                            >
                                                {
                                                    showEmailPartnerErrorMessage.message
                                                }
                                            </Alert>
                                        )}
                                    </Col>
                                </Row>

                                <Row
                                    className={[
                                        globalStyles.rowStyle,
                                        "justify-content-md-left",
                                    ].join(" ")}
                                >
                                    <Form.Group
                                        as={Col}
                                        xs={12}
                                        md={12}
                                        sm={12}
                                        controlId="partnerDescription"
                                        name="partnerDescription"
                                    >
                                        <Form.Label
                                            className={[
                                                formStyle.formLabel,
                                                formStyle.required,
                                            ].join(" ")}
                                        >
                                            Tell us about you:
                                        </Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            size="sm"
                                            type="textarea"
                                            name="partnerDescription"
                                            value={
                                                applyToBeOurPartnerFormState.partnerDescription
                                            }
                                            onChange={e => {
                                                handleApplyToBeOurPartnerFormChange(
                                                    e
                                                )
                                                setShowPartnerDescriptionErrorMessage(
                                                    { show: false }
                                                )
                                            }}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Col xs={12} md={12} sm={12}>
                                        {showPartnerDescriptionErrorMessage.show && (
                                            <Alert
                                                variant="danger"
                                                onClose={() =>
                                                    setShowPartnerDescriptionErrorMessage(
                                                        { show: false }
                                                    )
                                                }
                                                dismissible
                                            >
                                                {
                                                    showPartnerDescriptionErrorMessage.message
                                                }
                                            </Alert>
                                        )}
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={12} md={12} sm={12}>
                                        {showPartnerAlert.show && (
                                            <Alert
                                                variant={showPartnerAlert.type}
                                                onClose={() =>
                                                    setShowPartnerAlert({
                                                        show: false,
                                                    })
                                                }
                                                dismissible
                                            >
                                                {showPartnerAlert.msg}
                                            </Alert>
                                        )}
                                    </Col>
                                </Row>

                                <Row>
                                    <Col
                                        xs={12}
                                        sm={12}
                                        md={{ span: 3, offset: 0 }}
                                    >
                                        <Button
                                            type="button"
                                            className={[
                                                homepageStyle.submitButton,
                                                homepageStyle.homepageButton,
                                                globalStyles.rowStyle,
                                                "justify-content-md-left",
                                            ].join(" ")}
                                            onClick={
                                                validateAndSaveApplyToBeOurPartnerFormData
                                            }
                                        >
                                            Submit Inquiry
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Layout>
    )
}
export const query = graphql`
    query {
        fillFormIcon: file(relativePath: { eq: "icons/fill-form-icon.png" }) {
            childImageSharp {
                fluid(maxWidth: 1800) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        matchingIcon: file(relativePath: { eq: "icons/matching-icon.png" }) {
            childImageSharp {
                fluid(maxWidth: 1800) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        zoomIcon: file(relativePath: { eq: "icons/zoom-icon.png" }) {
            childImageSharp {
                fluid(maxWidth: 1800) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        whatsappIcon: file(relativePath: { eq: "icons/whatsapp-icon.png" }) {
            childImageSharp {
                fluid(maxWidth: 1800) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        reviews: site {
            siteMetadata {
                reviews {
                    photo
                    name
                }
            }
        }
        reviewsImages: allFile(
            filter: { relativePath: { regex: "/reviews/.*/" } }
        ) {
            edges {
                node {
                    childImageSharp {
                        fluid(maxWidth: 900) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                    name
                }
            }
        }
        checkIcon: file(relativePath: { eq: "icons/check-icon.png" }) {
            childImageSharp {
                fluid(maxWidth: 1800) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        gallery: allFile(filter: { relativePath: { regex: "/gallery/.*/" } }) {
            edges {
                node {
                    publicURL
                    childImageSharp {
                        fluid(maxWidth: 300) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                    name
                }
            }
        }
        groupPhoto: file(relativePath: { eq: "group-photo.png" }) {
            childImageSharp {
                fluid(maxWidth: 1800) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        logo: file(relativePath: { eq: "logo.png" }) {
            publicURL
        }
    }
`

export default IndexPage
